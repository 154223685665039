// Place all the styles related to the sessions controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

/* choose a background, put @background_class= 'adminpage' in the controller action */

.landing-modal {
  position:absolute;
  width:100%;
  height:530px;
  opacity:.9;
  pointer-events:none;
  background-color:#ceb1c2;
  -webkit-filter: grayscale(50%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(50%);
  border:solid 10px white;
}

.get-started-button {
  font-size:25px;
  margin:auto;
  border:solid 1px;
  width:300px;
  text-align:center;
  padding:20px 20px 15px 15px;
  border-radius:25px;
  background-color:rgba(255, 255, 255, .2);
  letter-spacing:.08em;
}
.get-started-button:hover {
  background-color:rgba(255, 255, 255, .3);
}


.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.sidenav {
  overflow:hidden;
  display: none;
  height: 80%;
  width: 300px;
  position: fixed;
  z-index: 999;
  top: 40px;
  right: 0;
  background-color: rgba(88, 88, 90, 0.9);
  padding-top: 5px;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.1);
}

.sidenav2 {
  overflow:hidden;
  display: none;
  height: 85%;
  width: 385px;
  position: fixed;
  z-index: 999;
  top: 40px;
  right: 0;
  background-color: #cfd1d1; /* rgba(88, 88, 90, 0.9); */
  padding-top: 5px;
  box-shadow: 4px 4px 3px 2px rgba(0, 0, 0, 0.1);
}


.gmlinks {
  a {
  color: #575759;

  &:visited {
    color: #575759;
  }

  &:hover {
    color: #ffa3de;
  }
}
}

/* a class needs to explicitly cover links, cant be just color, doesnt work!! */
.gmlinks2 {
  a:link { color: #b4b7b7; }
  a:visited { color: #b4b7b7;}
  a:hover {color: #ffa3de;}
}


.landingbutton {
  font-size:15px;
  font-family:dorisbr;
  color:#cfd1d1;
  width:100%;
  padding:7px;
  border-radius:10px;
  border:solid 2px #cfd1d1;
  background-color: rgba(255, 163, 222, .1);
}

.landingbutton:hover {
  font-size:15px;
  font-family:dorisbr;
  color:#cfd1d1;
  width:100%;
  padding:7px;
  border-radius:10px;
  border:solid 2px #ffa3de;
  background-color: rgba(255, 163, 222, .2);
}

.landingbutton2 {
  font-size:22px;
  font-family:dorisbr;
  color:#cfd1d1;
  width:100%;
  padding:7px;
  border-radius:30px;
  background-color: #C762A8;
  /* background-color: #ceb1c2;color:#C762A8; */
}

.landingbutton2:hover {
  font-size:22px;
  font-family:dorisbr;
  color:#cfd1d1;
  width:100%;
  padding:7px;
  border-radius:30px;
  background-color: #CF95B9;
  /* background-color: #c8a7ba;CF95B9 */
}



.landingpage-title {
  font-family: DORISBR;
  font-size: 40px;
  color: #383638; /* footer color */
  line-height: 45px;
  margin-top: 10px;
}
.landingpage-title-lg {
  font-family: DORISBR;
  font-size: 60px;
  color: #2f2d2f;  /* #383638;  footer color */
  line-height: 65px;
  margin-top: 10px;
}

.landingpage-title-lg-vid {
  font-family: DORISBR;
  font-size: 30px;
  color: #1f1e1f;  /* #383638;  footer color */
  line-height: 32px;
  margin-top: 10px;
}

.landingpage-text {
  font-size: 15px;
  color: #cfd1d1; /* #383638; footer color */
}

.product-checkout-bg {
  background: linear-gradient(130deg, rgba(237,220,231,1) 10%, rgba(213,187,203,1) 70%);
  background-repeat: no-repeat;
  background-size: cover;
}

.signinform {
  width: 50%;
  text-align: center;
  /* color: #575759;         font color */
  margin: 0 0 0 25%;     /*center fields */
}
a {
  color: black;
  /* text-decoration-line: underline; */

&:visited {
    color: black; /* #929292; */
  }

&:hover {
    color: #ffa3de;
    text-decoration-line: none;
  }
}

.graylinks {
  a {
    color: #cfd1d1;
    text-decoration-line: none;

  &:visited {
      color: #cfd1d1; /* #929292; */
      text-decoration-line: none;
    }

  &:hover {
      color: #F7BBE6;
      text-decoration-line: none;
    }
  }
}

.whitelinks {
  a {
    color: white;
    text-decoration-line: none;

  &:visited {
      color: white; /* #929292; */
      text-decoration-line: none;
    }

  &:hover {
      color: #ffa3de;
      text-decoration-line: none;
    }
  }
}


.btn-pink {
  color:#575759;
  background: rgba(206, 177, 194, 1); /* rgb(255,163,222);
  background: linear-gradient(0deg, rgba(255,163,222,1) 5%, rgba(205,106,217,0.36318277310924374) 100%); */
}
.btn-pink:hover {
  color:#575759;
  border: solid 1px #a1a5a5;
  background-color: #e2d0da;
}
.btn-pink-inactive {
  color: #575759;
  background-color: #d4c4cd;
}

.btn-studio {
  text-align:center;
  float:left;
  background-color:#cfd1d1;
  border-radius:20px;
  padding:6px 0px 6px 0px;
  margin:3.5px 0 3.5px 0;
  width:100%;
  font-size:11.5px;
  color:#575759;
  text-decoration:none;
}

.btn-studio:hover {
  text-align:center;
  float:left;
  background-color:#cfd1d1;
  border-radius:20px;
  padding:6px 0px 6px 0px;
  margin:3.5px 0 3.5px 0;
  width:100%;
  font-size:11.5px;
  color:#575759;
  text-decoration:none;
}

.pinkline {
  height: 1px;
  background: #ffa3de;
}

.pinkline2 {
  height: 1px;
  background: #ffa3de;
  width: 80%;
  margin: 10% 0 7% 10%;
}

.pinklinetop {
  height: 5px;
  background: #ceb1c2;
  background: #C762A8;
  background: #B29F6B;
  background: #000000;
  background: #B6A676;
  background: #E2C1D8;
  background: #C593B7;
  width: 100%;
  position: absolute;
  top: 0;
}

.pinklinebottom {
  height: 3px;
  background: #F5E8ED;  /* #FCD9E6; #ffa3de;  */
  width: 100%;
}

.grayline {
  height: 1px;
  background: #cfd1d1;
}
.whiteline {
  height: 1px;
  background: white;
}
.darkgrayline {
  height: 1px;
  background: #575759;
}

.thirdgrayline {
  height: 1px;
  background: #cfd1d1;
  width: 80%;
  margin: 6% 0 4% 10%;
}

.darkline {
  height: 1px;
  background: #575759;
}

.titletext {
  font-size: 20px;
  color: #575759;
}
.graytitletext {
  font-size: 20px;
  color: #cfd1d1;
}
.pinktitletext {
  font-size: 20px;
  color: #ffa3de;
}
.darktitletext {
  font-size: 20px;
  color: black;
}

.scoretext {
  font-size: 40px;
  color: #cfd1d1; /* #ffa3de;  #cfd1d1; */
  /* text-shadow: #959597 0px 0px 1px,   #959597 0px 0px 1px,   #959597 0px 0px 1px; */
}

.usernametext {
  font-size: 25px;
  color: #cfd1d1;
  margin: 15px 5px 15px 5px;
  border-radius: 10px;
  background-color: #575759;
  font-weight: bold;
  border: solid 0px rgba(255, 163, 222, .2);
}

.emailusernametext {
  text-align: center;
  font-family: 'blenda';
  font-size: 25px;
  color: #cfd1d1;
  font-weight: bold;
  letter-spacing: .08em;
  text-shadow:
   -1px -1px 0 #939395,
    1px -1px 0 #939395,
    -1px 1px 0 #939395,
     1px 1px 0 #939395;

}

.whitetext {
  color: white;
  font-family: sans-serif;
  text-decoration: none;
}
.graytext {
  color: #cfd1d1;
  font-family: sans-serif;
}

.graytext2 {
  color: #b4b7b7;
  color: #ffffff;
  font-family: sans-serif;
}

.pinktext {
  color: #ffa3de;
  font-family: sans-serif;
  text-decoration: none;
}

.email_subject {
  font-weight: bold;
  color: #ceb1c2;
  font-family: sans-serif;
  text-decoration: none;
}
.email_text {
  color: #858589;
  font-family: sans-serif;
  text-decoration: none;
}

.tabs {
  border-radius: 8px;
  border-left: solid -1px black;
  border-top: solid -1px black;
  border-right: solid -1px black;
}

.pinktabs {
  color: #ffa3de;
  font-size: 25px;
  font-family:favablack;
  text-shadow:
   -1px -1px 0 #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
     1px 1px 0 #FFF;
}

.graytabs {
  color: #858a8a;
  font-size: 25px;
  font-family:favablack;
  text-shadow:
   -1px -1px 0 #FFF,
    1px -1px 0 #FFF,
    -1px 1px 0 #FFF,
     1px 1px 0 #FFF;
}

.stroke-black {
text-shadow:
 -1px -1px 0 #000,
  1px -1px 0 #000,
  -1px 1px 0 #000,
   1px 1px 0 #000;
 }

 .stroke-darkgray {
 text-shadow:
  -1px -1px 0 #575759,
   1px -1px 0 #575759,
   -1px 1px 0 #575759,
    1px 1px 0 #575759;
  }

  .stroke-darkgray-50 {
  text-shadow:
   -.5px -.5px 0 #575759,
    .5px -.5px 0 #575759,
    -.5px .5px 0 #575759,
     .5px .5px 0 #575759;
   }

   .stroke-darkgray-51 {
     -webkit-text-stroke: 0px black;
    }

   .stroke-darkgray-25 {
   text-shadow:
    -.25px -.25px 0 #89898b,
     .25px -.25px 0 #89898b,
     -.25px .25px 0 #89898b,
      .25px .25px 0 #89898b;
    }

.stroke-lightgray {
  text-shadow:
   -1px -1px 0 #cfd1d1,
   1px -1px 0 #cfd1d1,
   -1px 1px 0 #cfd1d1,
   1px 1px 0 #cfd1d1;
}

.greentextbigstroke {
  color: #0ba828;
  font-size: 110px;
  font-family: serif;
  line-height: 80px;
  height: 100px;
  letter-spacing: -1px;
  text-shadow:
   -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;
}

.blacktext {
  color: black;
  font-family: sans-serif;
  text-decoration: none;
}

.loginpglinkpipe {
  color: #cfd1d1;
  font-family: sans-serif;
  text-decoration: none;
}

/* two ways to setup links like below... or see example up top with & */
/* i think you can delete loginpglink */
.loginpglink:a {
  color: #b4b7b7;
  font-family: sans-serif;
  text-decoration: none;
}

.loginpglink:hover {
  color: #ffa3de;
  font-family: sans-serif;
  text-decoration: none;
}

.loginpglink:visited {
  color: #b4b7b7;
  font-family: sans-serif;
  text-decoration: none;
}

.graylinks3 {
  color: #878c8c;
  text-decoration: none;
}

.graylinks3:hover {
  color: #B992A9;
  text-decoration: none;
  font-weight:bold;
}

.graylinks3:visited {
  color: #878c8c;
  text-decoration: none;
}

.logoimg {
  max-width:100%;
  max-height:30%;
  margin: 0 0 0 6%;
}

.logoimg-vid {
  max-width:100%;
  max-height:30%;
}


.loginpgitem {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -100;
}

.loginpgitem3 {
  float: left;
  font-size: 40px;
}

.lang-sel {
  display:inline;
  text-decoration:underline;
  font-size:20px;
  margin-left:8px;
  line-height:35px;
  vertical-align:middle;
}

.lang-reg {
  display:inline;
  text-decoration:none;
  font-size:20px;
  margin-left:8px;
  line-height:35px;
  vertical-align:middle;
}


/* correct working input controls  --  user edit form -- "settings"  */
.myform-control {
  border: solid 1px white;
  background: rgba(208, 210, 210, .4);  /* cfd1d1 background */
  width: 100%;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
}
.myform-control:focus {
  border-color: rgba(255, 163, 222, 1);
  box-shadow: 1px 1px 1px rgba(255, 163, 222, 1) inset, 1px 1px 8px rgba(255, 163, 222, 1);
  background: rgba(208, 210, 210, .4);
  outline: 0 none;
}

.myform-control-smenu {
  border: solid 1px white;
  background: rgba(208, 210, 210, .4);  /* cfd1d1 background */
  width: 100%;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
}
.myform-control-smenu:focus {
  border-color: rgba(171, 155, 122, 1);
  box-shadow: 1px 1px 1px rgba(171, 155, 122, 1) inset, 1px 1px 8px rgba(171, 155, 122, 1);
  background: rgba(171, 155, 122, .3);
  outline: 0 none;
}

.form-control {
  border:solid 1px #f2f2f2;
  background: rgba(208, 210, 210, .7);  /* cfd1d1 background */
  box-shadow: 0px;
  color:#ffffff;
}
.form-control:focus {
  border:solid 1px #ffffff;
  background: rgba(208, 210, 210, 1.0);
  box-shadow: 0px;
  color:#898989;
}

/*
box-shadow: inset 0 1px 1px rgba(255, 163, 222, .7), 0 0 8px rgba(255, 163, 222, 1.0);
*/

/* two step process, install this code in the page, then style it here...
<style>
input {
   outline: none;
 }
 textarea {
    outline: none;
  }
</style>
*/

.form-control-profile {
  border: solid 0px white;
  background: rgba(0, 0, 0, .06); /* white */
  box-shadow: inset 2px 2px 2px 0px #d2b7c7;  /* x, y, blur-radius, ? */
  width:100%;
  padding:10px;
  border:solid 0px;
  border-radius: 3px;
  font-family:dorisbr;
  font-size:13px;
  color:#98989A;
}
.form-control-profile:focus {
  border:solid 1px #ffa3de;
  background: rgba(0, 0, 0, .02);
  box-shadow: inset 2px 2px 2px 0px #d2b7c7;  /* x, y, blur-radius, ? */
  width:100%;
  padding:10px;
  border:solid 1px #ceb1c2;
  border-radius: 3px;
  font-size:13px;
}

/* login modal popup only */
.form-control1 {
  outline:none;
  box-shadow: inset 0px;
  border: solid 1px white;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
}

.form-control1:focus {
  /* background: rgba(208, 210, 210, 0.5); */
  border: solid 1px #ffa3de;
  box-shadow: 0px 0px 8px rgba(255, 163, 222, 1.0);
  outline:none;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
}


/* signupform */
.form-control2 {
  color:#575759;
  padding:4px;
  border-radius: 3px;
  width: 100%;
  box-shadow: 0px;
  border: solid 1px #cfd1d1;
  background-color: rgba(208, 210, 210, 0.2);
  outline: 0 none;
  height: 40px;
}
.form-control2:focus {
  color:#575759;
  padding: 4px;
  border-radius: 3px;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(255, 163, 222, 1) inset, 1px 1px 8px rgba(255, 163, 222, 1);
  border: solid 1px rgba(255, 163, 222, 1);
  background-color: rgba(208, 210, 210, .1);
  outline: 0 none;
  height: 40px;
}

/* signupform */
.form-control2a {
  padding:4px;
  border-radius: 3px;
  width: 100%;
  box-shadow: 0px;
  border: solid 0px #cfd1d1;
  background-color: rgba(255, 255, 255, 0.4);
  outline: 0 none;
  height: 40px;
}
.form-control2a:focus {
  padding: 4px;
  border-radius: 3px;
  width: 100%;
  border: solid 1px #cfd1d1;
  background-color: rgba(255, 255, 255, .7);
  outline: 0 none;
  height: 40px;
}

.form-control3 {
  border: solid 1px white;
  background: rgba(208, 210, 210, 0.7);  /* cfd1d1 background */
  width: 100%;
  padding: 3px;
  border-radius: 5px;
}
.form-control3:focus {
  border-color: rgba(255, 163, 222, 1);
  box-shadow: 1px 1px 1px rgba(255, 163, 222, 1) inset, 1px 1px 8px rgba(255, 163, 222, 1);
  background: rgba(208, 210, 210, 0.7);
  outline: 0 none;
}


.form-control4 {
  padding:4px;
  border-radius: 3px;
  width: 100%;
  border-color: rgba(255, 163, 222, 1);
  box-shadow: 0px;
  background: rgba(208, 210, 210, 0.5);
  outline: 0 none;
  height:40px;
  /* diff attribute below*/
  border: solid 1px white;
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder { /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder { /* IE 10+ */
  color: pink;
}
:-moz-placeholder { /* Firefox 18- */
  color: pink;
}
}
.form-control4:focus {
  padding:4px;
  border-radius: 3px;
  width: 100%;
  border-color: rgba(255, 163, 222, 1);
  box-shadow: 1px 1px 1px rgba(255, 163, 222, 1) inset, 1px 1px 8px rgba(255, 163, 222, 1);
  background: rgba(208, 210, 210, .6);
  outline: 0 none;
  height:40px;
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: pink;
}
::-moz-placeholder { /* Firefox 19+ */
  color: pink;
}
:-ms-input-placeholder { /* IE 10+ */
  color: pink;
}
:-moz-placeholder { /* Firefox 18- */
  color: pink;
}
}

/* login modal popup only */
.form-control5 {
  /* background: rgba(208, 210, 210, 0.5); */
  border: solid 1px #cfd1d1;
  background: rgba(208, 210, 210, .4);  /* cfd1d1 background */
  outline:none;
  box-shadow: inset 0px;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 20px;
  /* border-color: #ffa3de; */
  /* box-shadow: 0 5px 5px rgba(255, 163, 222, 1.0) inset, 0 0 0px rgba(255, 163, 222, 1.0); */
}
.form-control5:focus {
  /* background: rgba(208, 210, 210, 0.5); */
  /* border: solid 1px #ffa3de; */
  border: solid 1px #cfd1d1;
  background: rgba(208, 210, 210, .4);  /* cfd1d1 background */
  box-shadow: 0px 0px 8px rgba(255, 163, 222, 1.0);
  outline:none;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 20px;
}

/* modern added 5/6/20203 */
.form-control6 {
  /* background: rgba(208, 210, 210, 0.5); */
  border: solid 1px #ffffff;
  background: rgba(255, 255, 255, .3);  /* cfd1d1 background */
  outline:none;
  box-shadow: inset 0px;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 15px;
  /* border-color: #ffa3de; */
  /* box-shadow: 0 5px 5px rgba(255, 163, 222, 1.0) inset, 0 0 0px rgba(255, 163, 222, 1.0); */
}
.form-control6:focus {
  /* background: rgba(208, 210, 210, 0.5); */
  /* border: solid 1px #ffa3de; */
  border: solid 1px #ffffff;
  background: rgba(255, 255, 255, .7);  /* cfd1d1 background */
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
  outline:none;
  border-radius: 5px;
  width: 100%;
  padding: 5px;
  font-size: 15px;
}


#inner {
    width: 50%;
    margin: 0 auto;
}

#outter {
    width: 100%;
}

.signuppp-container {
  border: solid 1px #DDD8CC;
  padding: 15px;
  background-color: #e8eaed;
  align-self: center;
  border-radius: 20px;
  float: left;
  margin: 0 0 10px 0;
}

.modal-header-login {
    color:#ffa3de;
    padding:9px 15px;
    border-bottom:2px solid #ffa3de;
    background-color: #575759;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
     border-top-left-radius: 5px;
     border-top-right-radius: 5px;
}

.modal-body-login {
    color:#fff;
    padding:9px 15px;
    background-color: #cfd1d1;
}

.modal-body-review {
    color:#000;
    padding:9px 15px;
    background-color: #cfd1d1;
}
.modal-footer-login {
    color:#fff;
    padding:9px 15px;
    background-color: #575759;
    -webkit-border-bottom-left-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -moz-border-radius-bottomright: 5px;
     border-bottom-left-radius: 5px;
     border-bottom-right-radius: 5px;
}
.modal1-body {
    margin-top: 50px;
    margin-bottom: 20px;
    color:#000;
    border: solid 1px #575759;
    -webkit-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
    -moz-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
    box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);

    a {
      color: white;
      text-decoration-line:none;

    &:visited {
        color: white; /* #929292; */
        text-decoration-line:none;
      }

    &:hover {
        color: #ffa3de;
        text-decoration-line:none;
      }
    }

}

.modal-body-signup { /* not used!?? */
    background-color: rgba(88, 88, 90, 0.9);
    border: solid 1px #575759;
    -webkit-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
    -moz-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
    box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);


    a {
      color: white;
      text-decoration-line:none;

    &:visited {
        color: white; /* #929292; */
        text-decoration-line:none;
      }

    &:hover {
        color: #ffa3de;
        text-decoration-line:none;
      }
    }
    /*text-shadow:
     -1px -1px 0 #575759,
      1px -1px 0 #575759,
      -1px 1px 0 #575759,
       1px 1px 0 #575759;*/
}


.landing-btn {
  font-family:dorisbr;
  font-size:18px;
  text-decoration: none;
  text-align: center;
  border-radius:25px;
  padding:14px 16px 10px 16px;
  color: #575759;
  background-color: #cfd1d1; /* #6B83D2; */
  letter-spacing:.15em;
}

.landing-btn:hover {
  font-family:dorisbr;
  font-size:18px;
  text-decoration: none;
  text-align: center;
  border-radius:25px;
  padding:14px 16px 10px 16px;
  color: #ffa3de;
  background-color: #cfd1d1; /* #6B83D2; */
  letter-spacing:.15em;
}

.pink-shadow {
  -webkit-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
  -moz-box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
  box-shadow: 3px 3px 15px -1px rgba(56,56,57,0.7);
}
