/* add from node_modules */
@import "bootstrap";
@import 'bootstrap/dist/css/bootstrap';

/* inky foundation email stuff
@import '~foundation-sites/scss/foundation.scss';
@include foundation-global-styles;
@include foundation-grid; */

/* add my stylesheet directory css files */
@import 'sessions.scss';
@import 'animate4.0.css';
@import 'fontawesome.all.min.css';
@import 'pagination.css';
@import 'toggleswitch.css';
@import 'circle.css';
@import 'spectrum.css';

/* add some fonts to my project */

@font-face {
    font-family: 'Cabin';
    src: url('../fonts/Cabin-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'DORISBR';
    src: url('../fonts/DORISBR.TTF') format('truetype');
}
@font-face {
    font-family: 'Favablack';
    src: url('../fonts/Fava-black.ttf') format('truetype');
}
@font-face {
    font-family: 'GorriSans';
    src: url('../fonts/GorriSans.ttf') format('truetype');
}
@font-face {
    font-family: 'GravityBook';
    src: url('../fonts/GravityBook.ttf') format('truetype');
}
@font-face {
    font-family: 'Janitor';
    src: url('../fonts/Janitor.ttf') format('truetype');
}
@font-face {
    font-family: 'NerkoOne';
    src: url('../fonts/NerkoOne-Regular.otf') format('truetype');
}
@font-face {
    font-family: 'Blenda';
    src: url('../fonts/BlendaScript.otf') format('truetype');
}

/* add my css */

.slider-data {
  color:#898E8E; /* #D0D2D2; #E6E6E6; #98989a; */
  font-size: 11px;
  font-family: cabin;
}

.signupform-text-desc {
  color: #575757;
  text-align:left;
  width:100%;
  font-weight: bold;
  font-size: 13px;
  margin-top: 9px;
}

.index-event-div {
  border-radius: 3px;
  background:  rgba(255, 255, 255, 1);
  color: #575759;
  font-size: 12px;  /* from 14 */
  border: solid 1px #bec1c1;  /* #b1b4b4;  #cfd1d1; #DDD8CC; */
  float:left;
  width:100%;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(190,193,193,0.7);
  -moz-box-shadow: 0px 2px 3px 0px rgba(190,193,193,0.7);
  box-shadow: 0px 2px 3px 0px rgba(190,193,193,0.7);
}




/* add foundation emails css here for local rendering in email studio */

.bgcolor1 {
  background-color: #F5E8ED;
}

.bgcolor2 {
  background-color: #DBDBDB;
}

.bgcolor3 {
  background-color: #ceb1c2;
}

.bgcolor-pink {
  background-color: #F5E8ED;
}

.bgcolor-gray {
  background-color: #DBDBDB;
}

.bgcolor-white {
  background-color: #ffffff;
}

.bgcolor-pink-new {
  background-color: #ceb1c2;
}

.bgcolor-purple {
  /* background-color: #D480BB; */
  background-color: #C1509E;
}

.flb-purple {
  background-color: #5E4977;
}

.email-title {
  color:#C1509E;
  font-family:'Baloo';
}

.text-color {
  color: #383838;
}

.email-button {
  background-color: #C1509E;
  /* background-color: #ceb1c2; */
  color: #ffffff;
  font-size: 12px;
  padding: 10px 25px 10px 25px;
  letter-spacing: 0.15em;
  font-weight:bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.gmbutton-gray {
  background-color: #cfd1d1;
  color:#ffffff;
  padding: 10px 25px 10px 25px;
  letter-spacing: 0.15em;
  font-weight:bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.linestuff {
  line-height: 10px;
}

.landing-row-item {
  height:50px;
  background:#8f7bb1;
  text-align:center;
  color:#ffffff;
  font-family:cabin;
  padding-top:12px;
  font-size:17px;
  border-right:solid 1px #5f4a78;
}
